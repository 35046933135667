/**
 * NOTE: this is a copy of `@odo/components/widgets/category-selector/category-selector.tsx`
 * but with the new editor types and api calls.
 *
 * TODO: once we've moved over to the new deal editor we can remove the old category-selector.
 */
import { SearchableDropdown } from '@odo/components/elements/form-fields';
import { Flex, FlexItem } from '@odo/components/elements/layout/flex';
import { queryCategorySearchBreadcrumbs } from '@odo/graphql/categories';
import { getCategoryLabel } from '@odo/screens/deal/editor/conditions-and-category/helpers';
import { CategoryTypeEnum } from '@odo/types/api';
import {
  isEditorCategory,
  isEditorCategoryBreadcrumb,
} from '@odo/types/guards';
import type { CategoryOrBreadcrumb } from '@odo/types/portal';
import { debounce } from '@odo/utils/debounce';
import { useCallback, useRef, useState } from 'react';
import toast from 'react-hot-toast';

const ERROR_TOAST_ID = 'category-breadcrumbs-search-error';

const sortDailyShops = (results: CategoryOrBreadcrumb[]) =>
  [...results].sort((a, b) =>
    isEditorCategoryBreadcrumb(a) &&
    isEditorCategoryBreadcrumb(b) &&
    a.activeFromDate &&
    b.activeFromDate &&
    a.activeFromDate > b.activeFromDate
      ? -1
      : 1
  );

const searchCategoryBreadcrumbs = async ({
  term,
  categoryType = CategoryTypeEnum.category,
  signal,
  limit,
  setIsLoading,
  filterResults,
  setResults,
}: {
  term: string;
  categoryType?: CategoryTypeEnum;
  signal: AbortSignal;
  limit?: number;
  setIsLoading: (loading: boolean) => void;
  filterResults?: (results: CategoryOrBreadcrumb[]) => CategoryOrBreadcrumb[];
  setResults: (results: CategoryOrBreadcrumb[]) => void;
}) => {
  let isActive = true;
  setIsLoading(true);

  signal.addEventListener('abort', () => (isActive = false));

  try {
    const result = await queryCategorySearchBreadcrumbs({ term, categoryType });

    if (isActive && result?.breadcrumbs) {
      const breadcrumbs = filterResults
        ? filterResults(result.breadcrumbs)
        : result.breadcrumbs;
      setResults(limit ? breadcrumbs.slice(0, limit) : breadcrumbs);
      setIsLoading(false);
    }
  } catch (e) {
    if (!isActive) return;

    console.error(e);
    toast.error(
      e instanceof Error && typeof e.message === 'string'
        ? e.message
        : 'Error searching categories',
      {
        id: ERROR_TOAST_ID,
        duration: 10000,
      }
    );
    isActive && setIsLoading(false);
  }
};

const CategoryOption = ({ option }: { option: CategoryOrBreadcrumb }) => (
  <Flex flexDirection="column" gap={2}>
    {getCategoryLabel(option)}

    <Flex gap={2} justifyContent="space-between">
      <FlexItem alignSelf="flex-end">
        <strong>ID:&nbsp;{option.id}</strong>
      </FlexItem>

      <FlexItem>
        {isEditorCategoryBreadcrumb(option) && option.activeFromDate && (
          <strong>Active&nbsp;From:&nbsp;{option.activeFromDate}</strong>
        )}

        {isEditorCategory(option) &&
          option?.breadcrumb &&
          option?.breadcrumb.activeFromDate && (
            <strong>
              Active&nbsp;From:&nbsp;{option.breadcrumb.activeFromDate}
            </strong>
          )}
      </FlexItem>
    </Flex>
  </Flex>
);

const CategorySelector = ({
  label,
  placeholder,
  categoryType,
  closeOnSelect,
  categories,
  required,
  addCategory,
  removeCategory,
  filterResults,
}: {
  label: string;
  placeholder?: string;
  categoryType?: CategoryTypeEnum;
  closeOnSelect?: boolean;
  categories: CategoryOrBreadcrumb[];
  required?: boolean;
  // NOTE: these two are props for now so we can integrate with RP.
  // in future we might choose to do this from within this component itself.
  addCategory: (category: CategoryOrBreadcrumb) => void;
  removeCategory: (category: CategoryOrBreadcrumb) => void;
  filterResults?: (results: CategoryOrBreadcrumb[]) => CategoryOrBreadcrumb[];
}) => {
  const controllerRef = useRef<AbortController>();

  const [results, setResults] = useState<CategoryOrBreadcrumb[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  // always sort daily shops by date
  const filterResultsInternal = useCallback(
    (results: CategoryOrBreadcrumb[]) => {
      if (categoryType === CategoryTypeEnum.dailyShop) {
        return filterResults
          ? filterResults(sortDailyShops(results))
          : sortDailyShops(results);
      }
      return filterResults ? filterResults(results) : results;
    },
    [categoryType, filterResults]
  );

  const onSearch = useCallback(
    (term: string) => {
      if (controllerRef.current) controllerRef.current.abort();

      controllerRef.current = new AbortController();

      debounce(
        searchCategoryBreadcrumbs,
        500,
        controllerRef.current.signal
      )({
        term,
        categoryType,
        signal: controllerRef.current.signal,
        limit: 200,
        setIsLoading,
        filterResults: filterResultsInternal,
        setResults,
      });
    },
    [categoryType, filterResultsInternal]
  );

  return (
    <SearchableDropdown<CategoryOrBreadcrumb>
      // searching
      onSearch={onSearch}
      options={results}
      renderOption={option => <CategoryOption option={option} />}
      onSelect={addCategory}
      // selected options
      selectedOptions={categories}
      renderSelectedOption={option => <CategoryOption option={option} />}
      onRemove={removeCategory}
      // basics
      label={label}
      placeholder={placeholder}
      required={required}
      loading={isLoading}
      closeOnSelect={closeOnSelect}
    />
  );
};

export default CategorySelector;
