import Card from '@odo/components/elements/card';
import { Grid, GridItem } from '@odo/components/elements/layout';
import { Heading, Text } from '@odo/components/elements/typography';
import { useCreateDeal } from '@odo/hooks/deals';
import { useEffect } from 'react';
import { ReactComponent as IconSpinner } from '@odo/assets/svg/tube-spinner.svg';
import { cssColor } from '@odo/utils/css-color';
import Button from '@odo/components/elements/button';

const CreateDeal = () => {
  const { isReady, isCreating, error, createDeal } = useCreateDeal();

  useEffect(() => {
    isReady && createDeal();
  }, [isReady, createDeal]);

  return (
    <Grid placeContent="center" height="80vh">
      <Card maxWidth="90vw">
        <Grid width="450px" gap={[3, 4]} justifyItems="center">
          <Heading fontSize={[3, 4]} textAlign="center">
            Creating New Deal
          </Heading>

          {error ? (
            <Grid width="450px" gap={[2, 3]} justifyItems="center">
              <Text
                fontSize={[2, 3]}
                color={cssColor('palette-pink')}
                textAlign="center"
              >
                {error}
              </Text>

              <Button
                hue="blue"
                variant="outlined"
                loading={isCreating}
                onClick={createDeal}
              >
                Try Again
              </Button>
            </Grid>
          ) : (
            <GridItem justifySelf="center">
              <IconSpinner
                width={50}
                height={50}
                color={cssColor('palette-blue')}
              />
            </GridItem>
          )}
        </Grid>
      </Card>
    </Grid>
  );
};

export default CreateDeal;
