import config from '@odo/config';
import type { ApiCustomOption } from '@odo/types/api';
import type {
  CustomOptionTree,
  CustomOptionTreeValue,
} from '@odo/types/portal';

const cloneCustomOptionObjects = (
  customOptions: CustomOptionTree[]
): CustomOptionTree[] =>
  customOptions.map(option => ({
    ...option,
    values: option.values.map(value => ({
      ...value,
      childOptions: cloneCustomOptionObjects(value.childOptions),
    })),
  }));

const findOptionByValueGroupId = (
  options: CustomOptionTree[],
  groupId: number,
  exclude: CustomOptionTree[]
) =>
  options.find(
    option =>
      !exclude.some(({ id }) => id === option.id) &&
      option.values.some(value => value.groupId === groupId)
  );

const getValueChildrenGroupIds = (value: CustomOptionTreeValue) =>
  (value.childrenGroupIds || '')
    .split(',')
    // JS is dumb, parseInt('') === NaN, but parseInt('123abc') === 123, so we can't use it
    // however, Number('') === 0, so we need to filter out empty strings
    .filter(id => id !== '' && !isNaN(Number(id)))
    .map(id => Number(id));

const recursivelyBuildTree = (
  value: CustomOptionTreeValue,
  allOptions: CustomOptionTree[]
) => {
  const childOptions: CustomOptionTree[] = [];
  const childGroupIds = getValueChildrenGroupIds(value);

  // get all options that have a value which belongs to this value (exclude options already in array)
  childGroupIds.forEach(groupId => {
    const option = findOptionByValueGroupId(allOptions, groupId, childOptions);
    if (option) {
      childOptions.push(...cloneCustomOptionObjects([option]));
    }
  });

  // filter out any values of the child options that don't belong to this value (not as uncommon as you'd expect)
  // and then recursively build the tree for each child options value
  childOptions.forEach(childOption => {
    childOption.values = (
      config.customOptions.automaticallyLinkAllChildOptionValues
        ? childOption.values
        : childOption.values.filter(
            value =>
              typeof value.groupId !== 'undefined' &&
              childGroupIds.includes(value.groupId)
          )
    ).map(value => recursivelyBuildTree(value, allOptions));
  });

  value.childOptions = childOptions;

  return value;
};

const prepCustomOptionTree = (customOptions: ApiCustomOption[]) => {
  const allOptions: CustomOptionTree[] = customOptions.map(option => ({
    ...option,
    title: option.title || '',
    values: (option.values || []).map(value => ({
      ...value,
      sku: value.sku || '',
      price: value.price || 0,
      childOptions: [],
      quantity: {
        string: value.quantity?.toString() || '',
        number: value.quantity || 0,
      },
    })),
  }));

  const finalCustomOptions: CustomOptionTree[] = allOptions.map(option => {
    option.values = option.values.map(value =>
      recursivelyBuildTree(value, allOptions)
    );
    return option;
  });

  // if none of the other options values contain this option as a child, then this is a root option
  const rootOptions = finalCustomOptions.filter(
    option =>
      !finalCustomOptions.some(parentOption =>
        parentOption.values.some(value =>
          value.childOptions.some(({ id }) => id === option.id)
        )
      )
  );

  return rootOptions;
};

export default prepCustomOptionTree;
