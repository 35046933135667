/**
 * NOTE:
 * Buyer is now "Account Manager"
 * Buyers Assistant / Sales Assistant is now "Loader"
 */

import { ValidationModel } from './base/ValidationModel.jsx';

const BuyerAndSupplierProperties = {
  buyer: '',
  salesAssistant: '', // NOTE: No equivalent field (Phase 2 consideration)
  priority: '96',
  status: false,
  isLunchtimeProduct: false,
  isMainDeal: false,
  campaign: null,
  isBestSeller: false,
  platform: [],
  shop: '',
  isSupplierNew: false,
  supplier: null,
  campaignMailer: [],
  dealType: null,
  isNotStaffPurchase: false,
};

const BuyerAndSupplierValidators = {
  partial: {
    buyer: {
      validator: x => x.buyer !== 'NONE' && !!x.buyer,
      error: 'You must select a Account Manager.',
    },
    salesAssistant: {
      validator: x => x.salesAssistant !== 'NONE' && !!x.salesAssistant,
      error: 'You must select a Loader.',
    },
    campaign: {
      validator: x => !!x.campaign,
      error: 'You must select a Campaign (eg. none)',
    },
    supplier: {
      validator: x => x.supplier,
      error: 'Please select a Supplier',
    },
  },
  complete: {
    priority: {
      validator: x => x.priority >= 0,
      error: 'A priority must be entered.',
    },
  },
};

class DealBuyerAndSupplier extends ValidationModel {
  constructor() {
    super(BuyerAndSupplierProperties, BuyerAndSupplierValidators);

    /** @type {Boolean} */
    this.isNotStaffPurchase;

    /** @type {String} */
    this.buyer;
    this.salesAssistant;
    this.priority;
    this.status;
    this.isLunchtimeProduct;
    this.campaign;
    this.isBestSeller;
    this.platform;
    this.image;
    this.shop;
    this.supplier;
    this.brand;
    this.thumbnail;
    this.isSupplierNew;
    this.campaignMailer;
    this.dealType;
  }
}

DealBuyerAndSupplier.FIELDS = {
  BUYER: 'buyer',
  SALES_ASSISTANT: 'salesAssistant',
  PRIORITY: 'priority',
  ENABLED: 'status',
  IS_MAIN_DEAL: 'isMainDeal',
  CAMPAIGN: 'campaign',
  IS_BEST_SELLER: 'isBestSeller',
  PLATFORM: 'platform',
  IMAGE: 'image',
  SHOP: 'shop',
  SUPPLIER: 'supplier',
  THUMBNAIL: 'thumbnail',
  NEW_SUPPLIER: 'isSupplierNew',
  MAILERS: 'campaignMailer',
  DEAL_TYPE: 'dealType',
  IS_NOT_STAFF_PURCHASE: 'isNotStaffPurchase',
};

export { DealBuyerAndSupplier };
