import type { ApiCustomOption, ApiCustomOptionValue } from '@odo/types/api';
import { PriceTypeEnum } from '@odo/types/api';
import type {
  Action,
  SaveCustomOptionsMutations,
  CustomOptionsInputWithTmpId,
  CustomOptionValuesInputWithTmpId,
  CreateCustomOptionsMutation,
  UpdateOptionInputWithTmpId,
  UpdateCustomOptionValuesInputWithTmpId,
  UpdateCustomOptionMutation,
} from '@odo/contexts/custom-options-editor/types';
import { ActionTypeEnum } from '@odo/contexts/custom-options-editor/types';
import uuid, { isNewId } from '@odo/utils/uuid';
import type {
  CustomOptionTree,
  CustomOptionTreeValue,
} from '@odo/types/portal';
import { copyCustomOptionTrees } from './copy-custom-option-tree';
import prepCustomOptionTree from './prep-custom-option-tree';
import { persistActionList } from './cache';
import { isVariationSkuValid } from '@odo/screens/deal/editor/helpers';

/**
 * NOTE: currently only used in the RedPanda duplicate functions.
 * Might re-use it for our own one when we rebuild, but if not, remove this function.
 */
export const duplicateCustomOptions = ({
  customOptions,
  productId,
}: {
  customOptions: ApiCustomOption[];
  productId: ApiCustomOption['productId'];
}) => {
  if (!customOptions || customOptions.length === 0) return;

  duplicateEditorCustomOptions({
    customOptions: prepCustomOptionTree(customOptions),
    productId,
  });
};

export const duplicateEditorCustomOptions = ({
  customOptions,
  productId,
}: {
  customOptions: CustomOptionTree[];
  productId: ApiCustomOption['productId'];
}) => {
  if (!customOptions || customOptions.length === 0) return;

  const copiedOptions = copyCustomOptionTrees({ customOptions });
  persistActionList({
    productId: productId,
    actionList: [
      {
        type: ActionTypeEnum.PasteOptions,
        productId,
        options: copiedOptions,
      },
    ],
    actionOffset: 0,
  });
};

export const getMaxGroupId = (customOptions: ApiCustomOption[]) => {
  let maxGroupId = 0;
  customOptions.forEach(option =>
    (option.values || []).forEach(value => {
      if (value.groupId && value.groupId > maxGroupId) {
        maxGroupId = value.groupId;
      }
    })
  );
  return maxGroupId;
};

export const getMaxSortOrder = <T extends { sortOrder?: number }>(args: T[]) =>
  args.reduce(
    (sortOrder, value) =>
      (value.sortOrder || 0) > sortOrder ? value.sortOrder || 0 : sortOrder,
    0
  );

export const calcValueQty = (
  value: CustomOptionTreeValue,
  autoSumEnabled = true,
  uniqueValueIds: string[] = [],
  /**
   * NOTE: this is mostly used to pass a value quantity if it's underlying data is stale
   */
  valueQtyLatest: Record<string, number> = {}
) => {
  if (uniqueValueIds.includes(value.valueId)) {
    return 0;
  }

  uniqueValueIds.push(value.valueId);

  return autoSumEnabled && value.childOptions.length > 0
    ? value.childOptions.reduce(
        (accQtyOptions, option) =>
          accQtyOptions +
          option.values.reduce(
            (accQtyValues, value) =>
              accQtyValues +
              calcValueQty(
                value,
                autoSumEnabled,
                uniqueValueIds,
                valueQtyLatest
              ),
            0
          ),
        0
      )
    : value.valueId in valueQtyLatest
    ? valueQtyLatest[value.valueId]
    : value.quantity.number || 0;
};

export const calcOptionQty = (
  option: CustomOptionTree,
  autoSumEnabled = true,
  uniqueValueIds: string[] = [],
  valueQtyLatest: Record<string, number> = {}
) =>
  option.values.reduce(
    (accQty, value) =>
      accQty +
      calcValueQty(value, autoSumEnabled, uniqueValueIds, valueQtyLatest),
    0
  );

export const getCumulativeQty = (
  customOptions: CustomOptionTree[],
  autoSumEnabled = true,
  uniqueValueIds: string[] = [],
  valueQtyLatest: Record<string, number> = {}
) => {
  const cumulativeQuantities = customOptions.map(option =>
    calcOptionQty(option, autoSumEnabled, uniqueValueIds, valueQtyLatest)
  );

  // we only want the smallest quantity of all the root options (not the additive or max)
  const nextCumulativeQty =
    cumulativeQuantities.length > 0
      ? Math.min(...cumulativeQuantities)
      : undefined;

  return nextCumulativeQty;
};

/**
 * Validation.
 */
export const getOptionFieldError = (
  option: CustomOptionTree,
  field: 'title'
) => {
  let error: string | undefined;
  if (field === 'title' && !option.title) {
    error = 'Dropdown title is required';
  }
  return error;
};

export const getValueFieldError = (
  value: CustomOptionTreeValue,
  field: 'title' | 'sku'
) => {
  let error: string | undefined;
  if (field === 'title' && !value.title) {
    error = 'Value is required';
  }
  if (field === 'sku') {
    if (!value.sku) {
      error = 'SKU is required';
    } else if (value.sku === '0') {
      error = 'SKU cannot be 0';
    } else if (!isVariationSkuValid(value.sku)) {
      error = 'SKU is invalid';
    }
  }
  return error;
};

/**
 * Some union types for ease of use.
 */
type Mutation = CreateCustomOptionsMutation | UpdateCustomOptionMutation;

type FoundCustomOption = Mutation extends CreateCustomOptionsMutation
  ? CustomOptionsInputWithTmpId | undefined
  : UpdateOptionInputWithTmpId | undefined;

type FoundValue = Mutation extends CreateCustomOptionsMutation
  ? CustomOptionValuesInputWithTmpId | undefined
  : UpdateCustomOptionValuesInputWithTmpId | undefined;

/**
 * Type guard for our unions
 */
const isCreateMutation = (
  mutation: Mutation
): mutation is CreateCustomOptionsMutation =>
  typeof (mutation as CreateCustomOptionsMutation).args.customOptions !==
  'undefined';

/**
 * Finding existing mutations.
 */
export const findMutationByOption = ({
  optionId,
  mutations,
}: {
  optionId: ApiCustomOption['id'];
  mutations: SaveCustomOptionsMutations;
}) => {
  let mutation: Mutation | undefined;
  if (isNewId(optionId)) {
    mutation = mutations.createCustomOptions.find(
      ({ meta: { customOptionIds } }) => customOptionIds.includes(optionId)
    );
  } else {
    mutation = mutations.updateCustomOption.find(
      ({ meta: { customOptionIds } }) => customOptionIds.includes(optionId)
    );
  }
  return mutation;
};

export const findMutationByValue = ({
  valueId,
  mutations,
}: {
  valueId: ApiCustomOptionValue['valueId'];
  mutations: SaveCustomOptionsMutations;
}) =>
  [...mutations.createCustomOptions, ...mutations.updateCustomOption].find(
    ({ meta: { valueIds } }) => valueIds.includes(valueId)
  );

/**
 * Finding objects in mutations.
 */
export const findCustomOptionInMutation = ({
  optionId,
  mutation,
}: {
  optionId: ApiCustomOption['id'];
  mutation: Mutation;
}): FoundCustomOption => {
  let customOption:
    | CustomOptionsInputWithTmpId
    | UpdateOptionInputWithTmpId
    | undefined;
  if (isCreateMutation(mutation)) {
    customOption = mutation.args.customOptions.find(
      ({ tmpOptionId }) => tmpOptionId === optionId
    );
  } else {
    customOption = mutation.args.customOption;
  }
  return customOption;
};

export const findCustomOptionAndValueInMutation = ({
  valueId,
  mutation,
}: {
  valueId: ApiCustomOptionValue['valueId'];
  mutation: Mutation;
}): { customOption: FoundCustomOption; value: FoundValue } => {
  let customOption:
    | CustomOptionsInputWithTmpId
    | UpdateOptionInputWithTmpId
    | undefined;
  let value:
    | CustomOptionValuesInputWithTmpId
    | UpdateCustomOptionValuesInputWithTmpId
    | undefined;

  if (isCreateMutation(mutation)) {
    for (const option of mutation.args.customOptions) {
      const foundValue = (option.values || []).find(
        ({ tmpValueId }) => tmpValueId === valueId
      );
      if (foundValue) {
        customOption = option;
        value = foundValue;
        break;
      }
    }
  } else {
    const foundValue = (mutation.args.customOption.values || []).find(
      ({ valueId: id, tmpValueId }) => tmpValueId === valueId || id === valueId
    );
    if (foundValue) {
      customOption = mutation.args.customOption;
      value = foundValue;
    }
  }

  return { customOption, value };
};

/**
 * Finding objects in option inputs.
 */
export const findCustomOptionAndValueInOptionInputs = ({
  valueId: matchingValueId,
  customOptions,
}: {
  valueId: ApiCustomOptionValue['valueId'];
  customOptions: CustomOptionsInputWithTmpId[];
}) => {
  let customOption: CustomOptionsInputWithTmpId | undefined;
  let value: CustomOptionValuesInputWithTmpId | undefined;
  for (const option of customOptions) {
    const foundValue = (option.values || []).find(
      ({ tmpValueId }) => tmpValueId === matchingValueId
    );
    if (foundValue) {
      customOption = option;
      value = foundValue;
      break;
    }
  }
  return { customOption, value };
};

/**
 * Finding objects in remote options.
 */
export const findCustomOptionAndValueInRemoteOptions = ({
  valueId: matchingValueId,
  customOptions,
}: {
  valueId: ApiCustomOptionValue['valueId'];
  customOptions: ApiCustomOption[];
}) => {
  let customOption: ApiCustomOption | undefined;
  let value: ApiCustomOptionValue | undefined;
  for (const option of customOptions) {
    const foundValue = (option.values || []).find(
      ({ valueId }) => valueId === matchingValueId
    );
    if (foundValue) {
      customOption = option;
      value = foundValue;
      break;
    }
  }
  return { customOption, value };
};

/**
 * Making mutations.
 */
export const makeUpdateMutation = ({
  customOption,
  action,
  newValueId,
  newValue,
}: {
  customOption: ApiCustomOption;
  action?: Action;
  newValueId?: ApiCustomOptionValue['valueId'];
  newValue?: UpdateCustomOptionValuesInputWithTmpId;
}) => ({
  // anything not included in this mutation will be lost...
  meta: {
    mutationId: uuid(), // each mutation needs a unique ID
    actions: [...(action ? [action] : [])],
    customOptionIds: [customOption.id],
    valueIds: [
      // we need ALL valueIds in this array for finding values in future
      ...(customOption.values || []).map(({ valueId }) => valueId),
      ...(newValueId ? [newValueId] : []),
    ],
    completed: false,
    ready: true,
  },
  args: {
    optionId: customOption.id,
    customOption: {
      // NOTE: needs all fields, and can't just spread from remote custom options, so...
      id: customOption.id,
      title: customOption.title,
      type: customOption.type,
      dependency: customOption.dependency,
      isRequired: customOption.isRequired,
      sortOrder: customOption.sortOrder,
      description: customOption.description,
      values: [
        ...(customOption.values || []).map(value => ({
          valueId: value.valueId,
          title: value.title,
          price: value.price,
          priceType: value.priceType || PriceTypeEnum.fixed,
          sku: value.sku,
          sortOrder: value.sortOrder,
          quantity: value.quantity,
          cost: value.cost,
          default: value.default,
          groupId: value.groupId,
          childrenGroupIds: value.childrenGroupIds,
        })),
        ...(newValue
          ? [
              {
                ...newValue,
                sortOrder: getMaxSortOrder(customOption.values || []) + 1,
              },
            ]
          : []),
      ],
    },
  },
});

export const findOrMakeOptionMutation = ({
  customOptions,
  mutations,
  action,
  optionId,
}: {
  customOptions: ApiCustomOption[];
  mutations: SaveCustomOptionsMutations;
  action?: Action;
  // NOTE: only optional so we don't have to conditionally call this function
  optionId?: ApiCustomOption['id'];
}) => {
  let existingMutation: Mutation | undefined;
  let newMutation: UpdateCustomOptionMutation | undefined;
  let option:
    | CustomOptionsInputWithTmpId
    | UpdateOptionInputWithTmpId
    | undefined;

  if (typeof optionId !== 'undefined') {
    existingMutation = findMutationByOption({ optionId, mutations });
    if (existingMutation) {
      const customOption = findCustomOptionInMutation({
        optionId,
        mutation: existingMutation,
      });
      if (customOption) {
        option = customOption;
      } else {
        // dead-end
        console.warn(
          'Found option mutation, but not the option therein... somehow'
        );
      }
    } else if (!isNewId(optionId)) {
      const customOption = customOptions.find(({ id }) => id === optionId);
      if (customOption) {
        newMutation = makeUpdateMutation({
          customOption,
          action,
        });

        option = newMutation.args.customOption;
      }
    } else {
      // dead-end
      console.warn(
        "Option is new but we couldn't find a mutation for it somehow, so fail"
      );
    }
  }

  let mutation: Mutation | undefined;
  if (existingMutation) {
    mutation = existingMutation;
  } else if (newMutation) {
    mutation = newMutation;
  }

  const mutationCallback = () => {
    // update mutation meta and add them to the list
    if (existingMutation && action) {
      existingMutation.meta.actions.push(action);
    }

    // add new mutation to list
    if (newMutation) {
      mutations.updateCustomOption.push(newMutation);
    }
  };

  return { option, mutation, mutationCallback };
};

export const findOrMakeValueMutation = ({
  customOptions,
  mutations,
  action,
  valueId,
}: {
  customOptions: ApiCustomOption[];
  mutations: SaveCustomOptionsMutations;
  action?: Action;
  // NOTE: only optional so we don't have to conditionally call this function
  valueId?: ApiCustomOptionValue['valueId'];
}) => {
  let existingMutation: Mutation | undefined;
  let newMutation: UpdateCustomOptionMutation | undefined;
  let value:
    | CustomOptionValuesInputWithTmpId
    | UpdateCustomOptionValuesInputWithTmpId
    | undefined;

  if (typeof valueId !== 'undefined') {
    existingMutation = findMutationByValue({
      valueId: valueId,
      mutations,
    });
    if (existingMutation) {
      // find the option and value
      const { customOption, value: foundValue } =
        findCustomOptionAndValueInMutation({
          valueId: valueId,
          mutation: existingMutation,
        });
      if (customOption && foundValue) {
        value = foundValue;
      } else {
        // dead-end
        console.warn(
          "Found values mutation, but not the value and it's option therein... somehow"
        );
      }
    } else if (!isNewId(valueId)) {
      // will need a new mutation for updating the value
      // search through remote custom options for the value
      // make a new update mutation
      const { customOption, value: foundValue } =
        findCustomOptionAndValueInRemoteOptions({
          valueId: valueId,
          customOptions,
        });
      if (customOption && foundValue) {
        newMutation = makeUpdateMutation({
          customOption,
          action,
        });

        // we need to be editing the value that's in the mutation (so find it again)
        value = newMutation.args.customOption.values.find(
          ({ valueId: mutationValueId }) => mutationValueId === valueId
        );
      }
    } else {
      // dead-end
      console.warn(
        "Value is new but we couldn't find a mutation for it somehow, so fail"
      );
    }
  }

  let mutation: Mutation | undefined;
  if (existingMutation) {
    mutation = existingMutation;
  } else if (newMutation) {
    mutation = newMutation;
  }

  const mutationCallback = () => {
    // update mutation meta and add them to the list
    if (existingMutation && action) {
      existingMutation.meta.actions.push(action);
    }

    // add new mutation to list
    if (newMutation) {
      mutations.updateCustomOption.push(newMutation);
    }
  };

  return { value, mutation, mutationCallback };
};

export const removeCustomOptionMutation = ({
  optionId,
  mutations,
}: {
  optionId: ApiCustomOption['id'];
  mutations: SaveCustomOptionsMutations;
}) => {
  if (isNewId(optionId)) {
    // find the mutation for this option
    const mutation = mutations.createCustomOptions.find(
      ({ meta: { customOptionIds } }) => customOptionIds.includes(optionId)
    );
    if (mutation) {
      // remove this option from the mutation and it's meta
      const [first, ...rest] = mutation.args.customOptions.filter(
        ({ tmpOptionId }) => tmpOptionId !== optionId
      );
      if (first) {
        // if there are options left, update the mutation
        mutation.args.customOptions = [first, ...rest];
        mutation.meta.customOptionIds = mutation.meta.customOptionIds.filter(
          id => id !== optionId
        );
      } else {
        // else remove the entire mutation as it would now be a waste
        const mutationIndex = mutations.createCustomOptions.findIndex(
          ({ meta: { mutationId } }) => mutationId === mutation.meta.mutationId
        );
        if (mutationIndex !== -1) {
          mutations.createCustomOptions.splice(mutationIndex, 1);
        }
      }
    }
  } else {
    // we no longer need any update mutations for this option
    const mutationIndex = mutations.updateCustomOption.findIndex(
      ({ meta: { customOptionIds } }) => customOptionIds.includes(optionId)
    );
    if (mutationIndex !== -1) {
      mutations.updateCustomOption.splice(mutationIndex, 1);
    }
  }
};
