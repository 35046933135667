import type { Dispatch, SetStateAction } from 'react';

export enum FilterType {
  text = 'text',
  date = 'date',
  select = 'select',
  multiSelect = 'multiSelect',
  boolean = 'boolean',
  range = 'range',
  search = 'search',
}

export interface FilterInterface {
  label: string;
  key: string;
  type: FilterType;
  active?: boolean;
  exact?: boolean;
  value?: unknown;
  step?: number;
  defaultValue?: FilterInterface['value'] | (() => FilterInterface['value']);
  options?: { key: string; value: string }[];
}

export interface SearchFiltersContextType {
  isReady: boolean;
  filters: Readonly<FilterInterface[]>;
  setFilters: Dispatch<SetStateAction<FilterInterface[]>>;
  isLoadingDeals: boolean;
  setIsLoadingDeals: Dispatch<SetStateAction<boolean>>;
}
