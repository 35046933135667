/**
 * Query to return a list of Products that exist in a passed in array of IDs
 */

import { gql } from '@apollo/client';

export const GET_DEAL = gql`
  mutation GetDeal($id: String) {
    getProduct(id: $id)
  }
`;

export const GET_DEAL_BY_SKU = gql`
  query GetDealBySKU($sku: String!) {
    getProducts(
      filter: { select: { field: SKU, condition: IN, value: $sku } }
    ) {
      id
    }
  }
`;

export const GET_ODO_PRODUCT = gql`
  query GetProduct($productId: ID!) {
    getProduct(productId: $productId) {
      id
      buyer
      hasSalesHistory
      salesAssistant
      supplier
      isSupplierNew
      type
      brand
      sku
      url
      name
      shortName
      price
      cost
      rebateDiscount
      adminCost
      isDisplayRetail
      retail
      isSavingsInRands
      activeFromDate
      activeToDate
      area
      isAlcoholic
      isHygienic
      isParallelImport
      isReferable
      additionalInfo
      calloutText
      lockdownText
      condition
      isFragile
      isReturnableToSupplier
      warrantyPeriod
      warranty
      features
      videos
      leftAdditionalInfo
      moreDetails
      status
      taxClass
      pricing
      isLunchtimeProduct
      isInLunchtimeProductMailer
      isBestSeller
      platform
      isMainDeal
      campaign
      campaignMailer
      isBuyerSignedOff
      isSupplierSignedOff
      isFinalSignOff
      isSellout
      metaDescription
      metaTitle
      specialPrice
      visibility
      setNewFromDate
      setNewToDate
      countryManufactured
      applyMap
      manufacturerDisplayActualPrice
      manufacturerRetailPrice
      isGoogleCheckout
      metaKeywords
      isShippingApplied
      width
      length
      height
      weight
      isShippedIndividually
      pillOne
      pillTwo
      shippingCost
      isDeliveredBySupplier
      supplierRepacks
      originalStock
      surcharge
      surcharges {
        key
        value
      }
      discount
      priority
      isMembersOnly
      description
      shortDescription
      tabContentOne
      tabContentTwo
      isNotStaffPurchase
      dealType
      preview
      buyInStockType
      customerDeliveryTime
      isSampleReceived
      isPhotographedByStudio
    }
  }
`;
