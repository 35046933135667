import {
  useChangeProduct,
  useCurrentProduct,
  useProductEditor,
} from '@odo/contexts/product-editor';
import { useAttributeOptions } from '@odo/hooks/attributes';
import { AttributeCode } from '@odo/types/api';
import { useEffect, useMemo } from 'react';
import { dismiss, timer } from '@odo/utils/toast';

const TOAST_ID = 'auto-supplier-voucher';
const SUPPLIER_VOUCHER_ID = 'SUPPLIER_VOUCHER';
const AREA_NONE_ID = 'OTHER';
const ADMIN_COST_ZERO_ID = 'ADMINCOST_0_00';
const ADMIN_COST_DEFAULT_ID = 'ADMINCOST_17_50';
const CUSTOMER_DELIVERY_TIME_NA_ID = 'NOT_APPLICABLE';

/**
 * NOTE: It's possible that we'll create messy side-effect chains over time if we keep adding effects like this.
 *
 * We have a pending concept loosely called "templates/presets",
 * the idea being allowing a user to quickly apply a set of changes to the deal using a wizard/guide.
 * Something like that might be better in the long run once we can put some more thought into it.
 */
const SupplierVoucher = () => {
  const { dealType } = useCurrentProduct();
  const { getChanges } = useProductEditor();
  const change = useChangeProduct();

  const isSupplierVoucher = useMemo(
    () =>
      (dealType || []).some(
        ({ id }) => id.toLowerCase() === SUPPLIER_VOUCHER_ID.toLowerCase()
      ),
    [dealType]
  );

  const areaOptions = useAttributeOptions(AttributeCode.area);
  const adminCostOptions = useAttributeOptions(AttributeCode.adminCost);
  const customerDeliveryTimeOptions = useAttributeOptions(
    AttributeCode.customerDeliveryTime
  );

  const options = useMemo(
    () => ({
      areaOther: areaOptions.find(
        ({ value }) => value.toLowerCase() === AREA_NONE_ID.toLowerCase()
      ),
      adminCostZero: adminCostOptions.find(
        ({ value }) => value.toLowerCase() === ADMIN_COST_ZERO_ID.toLowerCase()
      ),
      adminCostDefault: adminCostOptions.find(
        ({ value }) =>
          value.toLowerCase() === ADMIN_COST_DEFAULT_ID.toLowerCase()
      ),
      customerDeliveryTimeNA: customerDeliveryTimeOptions.find(
        ({ value }) =>
          value.toLowerCase() === CUSTOMER_DELIVERY_TIME_NA_ID.toLowerCase()
      ),
    }),
    [areaOptions, adminCostOptions, customerDeliveryTimeOptions]
  );

  useEffect(() => {
    const isChanged = getChanges().some(
      ({ fieldId }) =>
        fieldId.toLowerCase() ===
        `dealType.${SUPPLIER_VOUCHER_ID}`.toLowerCase()
    );
    // exit if the field is unchanged
    if (!isChanged) return;

    // prepare our changes
    const nextArea =
      isSupplierVoucher && options.areaOther
        ? { id: options.areaOther.value, label: options.areaOther.label }
        : undefined;

    const nextCustomerDeliveryTime =
      isSupplierVoucher && options.customerDeliveryTimeNA
        ? {
            id: options.customerDeliveryTimeNA.value,
            label: options.customerDeliveryTimeNA.label,
          }
        : undefined;

    const nextAdminCost = isSupplierVoucher
      ? options.adminCostZero
        ? {
            id: options.adminCostZero.value,
            label: options.adminCostZero.label,
          }
        : undefined
      : options.adminCostDefault
      ? {
          id: options.adminCostDefault.value,
          label: options.adminCostDefault.label,
        }
      : undefined;

    // we're gonna make all field changes in one apply function to keep things cleaner
    const { undo } = change({
      fieldId: 'isSupplierVoucher.auto',
      label: `Supplier Voucher Automation: ${
        isSupplierVoucher ? 'Enabled' : 'Disabled'
      }`,
      apply: to => {
        to.isShippingApplied = !isSupplierVoucher;
        to.isShippedIndividually = isSupplierVoucher;
        to.isDeliveredBySupplier = isSupplierVoucher;

        to.area = nextArea;
        to.adminCost = nextAdminCost;
        to.customerDeliveryTime = nextCustomerDeliveryTime;
      },
    });

    // notify the user and allow them to undo
    const toastId = timer(
      'Automatically updated: admin cost, area, shipping applies, supplier delivers, and customer delivery time',
      {
        id: TOAST_ID,
        timeInMs: 15000,
        messageOptions: {
          action: {
            label: 'Undo',
            callback: () => {
              undo();
              dismiss(toastId);
            },
          },
        },
      }
    );
  }, [isSupplierVoucher, options, change, getChanges]);

  return null;
};

export default SupplierVoucher;
