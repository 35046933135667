const pad = (number: number, length = 2) =>
  number.toString().padStart(length, '0');

/**
 * Replacement for dateObjectToIso & dateObjectTimeOnly.
 *
 * In some cases we want to use the full ISO date.
 * But a lot of the time we just want to display a human readable date string without seconds.
 *
 * It would've been best to have a single function for both and we can do so here now.
 * But the original function signature was not well suited to extending.
 * And it was already used in far too many places for it be wise to refactor while
 * we still have untyped code in the repo.
 */
export const dateString: (
  date: Date,
  args?: { withDate?: boolean; withTime?: boolean; withSeconds?: boolean }
) => string = (date, args) => {
  if (!(date instanceof Date)) {
    throw new Error('Invalid argument: `date` is not of type `Date`');
  }

  const { withDate, withTime, withSeconds } = {
    withDate: true,
    withTime: true,
    ...args,
  };

  let dateString = '';
  if (withDate) {
    dateString += `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
      date.getDate()
    )}`;
  }

  if (withTime) {
    dateString += ` ${pad(date.getHours())}:${pad(date.getMinutes())}`;
  }

  if (withSeconds) {
    dateString += `:${pad(date.getSeconds())}`;
  }

  return dateString.trim();
};

/**
 * TODO: remove once we no longer use. might need to wait until we've removed all RP code.
 * @deprecated use `dateString` function instead
 */
export const dateObjectToIso = (date: Date, withTime = false) => {
  if (!(date instanceof Date)) {
    return '';
  }

  let dateString = `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
    date.getDate()
  )}`;

  if (withTime) {
    dateString += ` ${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(
      date.getSeconds()
    )}`;
  }

  return dateString;
};

/**
 * TODO: remove once we no longer use. might need to wait until we've removed all RP code.
 * @deprecated use `dateString` function instead
 */
export const dateObjectTimeOnly = (date: Date, withSeconds = false) => {
  if (!(date instanceof Date)) {
    return '';
  }

  let timeString = `${pad(date.getHours())}:${pad(date.getMinutes())}`;

  if (withSeconds) {
    timeString += `:${pad(date.getSeconds())}`;
  }

  return timeString;
};

export const daysAway = (days: number) => {
  const date = new Date();
  date.setDate(date.getDate() + days);
  date.setHours(0, 0, 0, 0);
  return date;
};

export const daysEnd = (days: number) => {
  const date = new Date();
  date.setDate(date.getDate() + days);
  date.setHours(23, 59, 59, 59);
  return date;
};
