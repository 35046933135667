/**
 * NOTE: this file is exclusively for the types on the BP API interfaces/queries/mutations/etc.
 *
 * TODO: consider using graphql-codegen for our types instead to keep them up-to-date:
 * https://www.graphql-code-generator.com/docs/guides/react#apollo-and-urql
 */

import type { AtLeast } from './helpers';

export interface ProductInventory {
  id: string;
  qty: number;
  isInStock: boolean;
  minSaleQuantity: number;
  useConfigMinSaleQty: boolean;
  maximumSaleQuantity: number;
  useConfigMaxSaleQty: boolean;
  isApplyMaxSaleQtyCustomerProfile: boolean;
  isApplyMaxSaleQtyToProductOptions: boolean;
  productId?: string;
  minQty?: number;
  useConfigMinQty?: boolean;
  isQuantityDecimal?: boolean;
  useConfigBackorder?: boolean;
  notifyStockQty?: number;
  useConfigNotifyStockQty?: boolean;
  isManageStock?: boolean;
  useConfigManageStock?: boolean;
  useConfigQuantityIncrement?: boolean;
  quantityIncrement?: number;
  useConfigEnableQuantityIncrements?: boolean;
  enableQuantityIncrements?: boolean;
  isDecimalDivide?: boolean;
  StockConfigQuantityIncrement?: number;
}

/**
 * NOTE: don't export this directly.
 * We have an ApiProduct type as a minor abstraction.
 * That should always be used instead.
 */
interface Product {
  id: string;
  attributeSet: string; // enum
  buyer: string;
  salesAssistant?: string;
  hasSalesHistory?: boolean;
  supplier: string;
  isSupplierNew: boolean;
  type: string; // enum
  brand: string;
  sku: string;
  url: string;
  name: string;
  shortName: string;
  price: number;
  cost: number;
  rebateDiscount: number;
  adminCost: string; // enum
  isDisplayRetail: boolean;
  retail: number;
  isSavingsInRands: boolean;
  activeFromDate: string;
  activeToDate: string;
  area: string; // enum
  isAlcoholic: boolean;
  isHygienic: boolean;
  isParallelImport: boolean;
  isReferable: boolean;
  additionalInfo: string;
  calloutText: string;
  lockdownText: string;
  condition: string; // enum
  isFragile: boolean;
  isReturnableToSupplier: boolean;
  warrantyPeriod: string; // enum
  warranty: string;
  features: string | null;
  videos: string; // NOTE: someday it would be great if this was an array
  leftAdditionalInfo: string[];
  moreDetails: string;
  status: boolean; // required field
  taxClass: string; // enum
  pricing: string;
  isLunchtimeProduct: boolean;
  isInLunchtimeProductMailer: boolean;
  buyInStockType: string; // enum
  isBestSeller: boolean;
  platform: string[]; // enum
  isMainDeal: boolean;
  campaign: string; // enum
  campaignMailer: string[]; // enum
  isBuyerSignedOff: boolean;
  isSupplierSignedOff: boolean;
  isFinalSignOff: boolean;
  isSellout: boolean;
  metaDescription: string;
  metaTitle: string;
  specialPrice: number; // NOTE: think this should be removed/ignored, don't think it's used anymore
  visibility: number;
  setNewFromDate: string; // ignore
  setNewToDate: string; // ignore
  countryManufactured: string;
  applyMap: number;
  manufacturerDisplayActualPrice: number;
  manufacturerRetailPrice: number; // should've been a boolean
  isGoogleCheckout: boolean; // huh, what on earth is this attribute used for?
  metaKeywords: string;
  isShippingApplied: boolean;
  width: number;
  length: number;
  height: number;
  weight: number;
  isShippedIndividually: boolean;
  pillOne: string;
  pillTwo: string;
  shippingCost: number;
  isDeliveredBySupplier: boolean;
  supplierRepacks: string; // enum
  originalStock: number;
  discount: number;
  priority: number;
  isMembersOnly: boolean; // ignore?
  description: string;
  shortDescription: string;
  tabContentOne: string;
  tabContentTwo: string;
  isNotStaffPurchase: boolean;
  preview: string;
  dealType: string[] | null; // enum
  isDailyDeal: boolean;
  customerDeliveryTime: string; // enum
  surcharge: number; // surcharge total
  surcharges: {
    key: string; // enum
    value: string;
  }[];
  isSampleReceived: boolean;
  isPhotographedByStudio: boolean;
  xtdDaysRequested: string | null;
  xtdDaysConfirmed: string | null;
  inventory: ProductInventory;
  categories?: ProductCategory[];
  images?: ProductImage[];
  sizeChart: ProductSizeChart;
}

interface CustomOptionValue {
  valueId: string;
  title: string;
  price?: number;
  priceType?: PriceTypeEnum;
  sku?: string;
  sortOrder?: number;
  quantity?: number | null;
  cost?: number;
  default?: boolean;
  // NOTE: the below two fields are how we connect custom option values to their parents
  childrenGroupIds?: string; // comma separated list of child option group IDs
  groupId?: number; // option group ID
}

interface CustomOption {
  id: string;
  productId: Product['id'];
  title?: string;
  type?: InputTypeEnum;
  isRequired?: boolean;
  dependency?: DependencyEnum;
  isOneTime?: boolean;
  sortOrder?: number;
  description?: string;
  values?: CustomOptionValue[];
}

// NOTE: all used for the product editor page
export enum AttributeCode {
  productType = 'ProductTypeEnum', // NOTE: only used on new products
  buyer = 'BuyersEnum',
  salesAssistant = 'SalesAssistantEnum',
  supplier = 'Suppliers',
  dealType = 'DealTypeEnum',
  campaign = 'CampaignEnum',
  campaignMailer = 'CampaignMailerEnum',
  platform = 'PlatformEnum',
  condition = 'ConditionEnum',
  taxClass = 'TaxClassEnum',
  area = 'AreaEnum',
  buyInStockType = 'BuyInStockEnum',
  warrantyPeriod = 'WarrantyPeriodEnum',
  adminCost = 'AdminCostEnum',
  status = 'status', // TODO: remove the please select option here
  supplierRepacks = 'SupplierRepacksEnum',
  customerDeliveryTime = 'CustomerDeliveryTimeEnum',
  // NOTE: used for custom options?
  inputType = 'InputTypeEnum',
  dependencyType = 'DependencyEnum',
  surcharges = 'Surcharges',
  priority = 'Priority',
  sizeChartMeasurements = 'SizeChartMeasurements',
  listingPages = 'ListingPages',
}

// NOTE: these only work on the getDropDownValues query args.
export enum DropDownName {
  applyMap = 'APPLY_MAP',
  displayActualPrice = 'DISPLAY_ACTUAL_PRICE',
  warrantyPeriod = 'WARRANTY_PERIOD',
  taxClass = 'TAX_CLASS',
  platform = 'PLATFORM',
  supplierRepacks = 'SUPPLIER_REPACKS',
  attributeSet = 'ATTRIBUTE_SET_ENUM',
  productType = 'PRODUCT_TYPE_ENUM',
  area = 'AREA',
  condition = 'CONDITION',
  buyers = 'BUYERS',
  salesAssistant = 'SALES_ASSISTANT',
  buyersMetadata = 'BUYERS_METADATA',
  suppliers = 'SUPPLIERS',
  priority = 'PRIORITY',
  campaign = 'CAMPAIGN',
  campaignMailer = 'CAMPAIGN_MAILER',
  visibility = 'VISIBILITY',
  adminCost = 'ADMIN_COST',
  buyInStockType = 'BUY_IN_STOCK_TYPE',
  dealType = 'DEAL_TYPE',
  leadTime = 'LEAD_TIME', // deprecated
  discount = 'DISCOUNT',
  msrpDisplayActualPriceType = 'MSRP_DISPLAY_ACTUAL_PRICE_TYPE',
  enableGoogleCheckout = 'ENABLE_GOOGLECHECKOUT', // the fuck is this doing on BP?!
  isMainDeal = 'IS_MAIN_DEAL',
  isNotStaffPurchase = 'IS_NOT_STAFF_PURCHASE',
  customerDeliveryTime = 'CUSTOMER_DELIVERY_TIME',
  sizeChartMeasurements = 'SIZECHART_MEASUREMENT',
  listingPages = 'LISTING_PAGES',
  updateDataMapper = 'UPDATE_DATA_MAPPER',
}

export enum InputTypeEnum {
  field = 'FIELD',
  area = 'AREA',
  file = 'FILE',
  dropdown = 'DROPDOWN',
  radio = 'RADIO',
  checkbox = 'CHECKBOX',
  multiple = 'MULTIPLE',
  swatch = 'SWATCH',
  multiSwatch = 'MULTISWATCH',
  hidden = 'HIDDEN',
  date = 'DATE',
  dateTime = 'DATETIME',
  time = 'TIME',
}

export enum DependencyEnum {
  no = 'NO',
  and = 'AND',
  or = 'OR',
}

export enum PriceTypeEnum {
  percentage = 'PERCENTAGE',
  fixed = 'FIXED',
}

export enum ViewModeEnum {
  visible = 'VISIBLE',
  backend = 'BACKEND',
  backendOnly = 'BACKENDONLY',
  disable = 'disable',
}

export enum CategoryFilterFields {
  id = 'ID',
  name = 'NAME',
  urlKey = 'URL_KEY',
  parentId = 'PARENT_ID',
  isActive = 'IS_ACTIVE',
  level = 'LEVEL',
  position = 'POSITION',
  activeToDate = 'ACTIVE_TO_DATE',
  activeFromDate = 'ACTIVE_FROM_DATE',
}

export interface AttributeMetadata {
  key: string;
  value: string;
}

export interface AttributeData {
  key: string;
  // NOTE: this value is almost useless now coz it's not returned by or sent to the API.
  value: string;
  metadata?: AttributeMetadata[];
}

export interface ApiAttribute {
  name: AttributeCode;
  data: AttributeData[];
}

export enum ImageType {
  email = 'IMAGE', // used as the email main image
  emailThumbnail = 'SMALL_IMAGE', // used as the email thumbnail
  website = 'THUMBNAIL', // used on the website product listings
}

/**
 * query getCategories
 */
export interface ApiCategory {
  id: number;
  name: string;
  parentCategory: ApiCategory['id'];
  activeFromDate: string;
  isActive: boolean;
}

export interface InputCategoriesSelect {
  field: CategoryFilterFields;
  condition: string;
  value: string;
}

export interface InputCategoriesFilter {
  limit?: number;
  page?: number;
  orderBy?: { field: string; direction: 'ASC' | 'DESC' };
  select?: InputCategoriesSelect[];
}

export interface QueryCategoriesArgs {
  filter?: InputCategoriesFilter;
}

export interface QueryCategoriesOutput {
  getCategories: ApiCategory[];
}

export interface ProductImage {
  id: string;
  filePath: string;
  url: string;
  position: number;
  label: string;
  excludeImageTypes: 1 | 0; // disabled/enabled
  imageTypes: ImageType[];
}

export interface ProductCategory {
  categoryId: string;
  categoryName: string;
}

export interface SizeChartImage {
  fileContents?: string;
  fileMimeType?: string;
  fileName?: string;
  fileLabel?: string;
  filePath?: string;
  url?: string;
  delete?: boolean;
}
export interface ProductSizeChart {
  id: string;
  productId?: number;
  recommendation: string;
  measurement: number;
  desktop: SizeChartImage;
  tablet: SizeChartImage;
  mobile: SizeChartImage;
  smDesktopImage?: SizeChartImage;
  smTabletImage?: SizeChartImage;
  smMobileImage?: SizeChartImage;
  createdAt?: string;
  updatedAt?: string;
  delete?: boolean;
}

export type CategoryWithBreadcrumb = ProductCategory & {
  breadcrumb?: ApiCategoryBreadcrumb;
};

export type ApiCategoryOrBreadcrumb =
  | CategoryWithBreadcrumb
  | ApiCategoryBreadcrumb;

export type ApiProduct = Product;
export type ApiCustomOption = CustomOption;
export type ApiCustomOptionValue = CustomOptionValue;

/**
 * query getProduct
 */
export interface QueryProductArgs {
  id: string;
}

// TODO: consider typing these as partials of the product
// as the fields that we get are based on the graphql fields selected
export interface QueryProductOutput {
  getProduct: ApiProduct;
}

/**
 * query getProducts
 */
export interface InputProductSelect {
  field: string;
  condition: string;
  value: string;
}

export interface InputProductFilter {
  limit?: number;
  page?: number;
  orderBy?: { field: string; direction: 'ASC' | 'DESC' };
  select?: InputProductSelect[];
}

export interface QueryProductsArgs {
  filter?: InputProductFilter;
}

export interface QueryProductsOutput {
  getProducts: ApiProduct[];
}

/**
 * query getProducts by SKU
 */
export interface InputProductFilterBySKU extends InputProductFilter {
  select: [
    {
      field: 'SKU';
      condition: 'IN';
      value: string;
    }
  ];
}

export interface QueryProductsBySKUArgs {
  filter: InputProductFilterBySKU;
}

export interface QueryProductsBySKUOutput {
  getProducts: Pick<ApiProduct, 'id'>[];
}

/**
 * query getProductImages
 */
export interface QueryProductImagesArgs {
  productId: string;
}

export interface QueryProductImagesOutput {
  getProductImages: ProductImage[];
}

/**
 * getCustomOptions
 */
export interface QueryCustomOptionsArgs {
  productId: ApiProduct['id'];
}

export interface QueryCustomOptionsOutput {
  getCustomOptions: ApiCustomOption[];
}

/**
 * query getAttributes (this is basically ALL dropdowns)
 */
export interface QueryAttributesOutput {
  getAttributes: ApiAttribute[];
}

/**
 * query getCategoryBreadcrumbs
 */
export enum CategoryTypeEnum {
  other = 'OTHER',
  category = 'CATEGORY',
  dailyShop = 'DAILY_SHOP',
  everydayEssentials = 'EVERYDAY_ESSENTIALS_SHOP',
  permanent = 'PERMANENT',
  giftVoucher = 'GIFT_VOUCHERS_SHOP',
}

export interface ApiCategoryBreadcrumb {
  id: string;
  name: string;
  breadcrumb: string;
  activeFromDate?: string;
  activeToDate?: string;
  type: string;
  relevance: number;
}

export interface QueryCategoryBreadcrumbsArgs {
  categoryIds: string[];
}

export interface QueryCategoryBreadcrumbsOutput {
  getCategoryBreadcrumbs: {
    count: number;
    breadcrumbs: ApiCategoryBreadcrumb[];
  };
}

/**
 * query getCategorySearchBreadcrumbs
 */
export interface QueryCategorySearchBreadcrumbsArgs {
  term: string;
  searchType?: 'FULLTEXT' | 'COMBINE' | 'LIKE';
  categoryType?: CategoryTypeEnum;
}

export interface QueryCategorySearchBreadcrumbsOutput {
  getCategorySearchBreadcrumbs: {
    count: number;
    breadcrumbs: ApiCategoryBreadcrumb[];
  };
}

/**
 * query getDropDownValues
 */
export interface QueryDropDownValuesArgs {
  name: DropDownName;
}

export interface QueryDropDownValuesOutput {
  getDropDownValues: { key: string; value: string }[];
}

/**
 * mutation updateProduct (from grid, where only a few fields can be changed)
 */
export interface MutationUpdateProductFromGridArgs {
  productId: ApiProduct['id'];
  input: {
    activeFromDate?: string;
    activeToDate?: string;
    priority?: number;
  };
}

export interface MutationUpdateProductFromGridOutput {
  updateProduct: AtLeast<ApiProduct, 'id'> & {
    activeFromDate?: ApiProduct['activeFromDate'];
    activeToDate?: ApiProduct['activeToDate'];
    priority?: ApiProduct['priority'];
  };
}

/**
 * mutation updateProduct (from product page, where all fields can be changed)
 */
export type UpdateProductInput = Partial<
  Omit<ApiProduct, 'preview' | 'inventory' | 'categories' | 'images'>
> & {
  inventory: Partial<Omit<ProductInventory, 'id'>>;
  categories: number[]; // input is different to the output here...
  images: Omit<ProductImage, 'url'>[];
};

export interface MutationUpdateProductArgs {
  productId: ApiProduct['id'];
  input: UpdateProductInput;
}

export interface MutationUpdateProductOutput {
  updateProduct: ApiProduct;
}

/**
 * mutation createProduct (from product page, where all fields can be changed)
 */
export type CreateProductInput = Partial<
  Omit<ApiProduct, 'id' | 'preview' | 'inventory' | 'categories' | 'images'>
> & {
  attributeSet: 'ONEDAYONLY'; // there is an enum, but this is the only option we use
  type: 'SIMPLE'; // there is an enum, but this is the only option we use
  // there are multiple options in magento -> product -> other:
  // 1 = not visible individually, 2 = catalog, 3 = search, 4 = catalog and search
  // we only ever use 4 though, so just hardcode it
  visibility: 4;
  shortDescription: ''; // not sure what it's used for, but it is required
  inventory: Partial<Omit<ProductInventory, 'id'>>;
  categories: number[]; // input is different to the output here...
  images: (Omit<ProductImage, 'url'> & { isDuplicate: boolean })[];
};

export interface MutationCreateProductArgs {
  input: CreateProductInput;
}

export interface MutationCreateProductOutput {
  createProduct: ApiProduct;
}

/**
 * mutation createCustomOptions
 */
export interface CustomOptionValuesInput {
  title: string;
  price: number;
  priceType: PriceTypeEnum;
  sku: string;
  sortOrder?: number;
  quantity?: number | null;
  cost?: number;
  default?: boolean;
  childrenGroupIds?: string;
  groupId?: number;
}

export interface CustomOptionsInput {
  title: string;
  type: InputTypeEnum;
  viewMode: ViewModeEnum;
  dependency?: DependencyEnum;
  isRequired?: boolean;
  sortOrder: number;
  isOneTime?: boolean;
  description?: string;
  values?: CustomOptionValuesInput[];
}

export interface MutationCreateCustomOptionsArgs {
  productId: ApiProduct['id'];
  customOptions: CustomOptionsInput[];
}

export interface MutationCreateCustomOptionsOutput {
  createCustomOptions: {
    status: string;
    code: string;
    message?: string;
  };
}

/**
 * mutation updateCustomOption
 */
export interface UpdateCustomOptionValuesInput {
  valueId?: ApiCustomOptionValue['valueId'];
  title: string;
  price?: number;
  priceType: PriceTypeEnum;
  sku?: string;
  sortOrder?: number;
  quantity?: number | null;
  cost?: number;
  default?: boolean;
  childrenGroupIds?: string;
  groupId?: number;
}

export interface UpdateOptionInput {
  id?: ApiCustomOption['id'];
  title?: string;
  type?: InputTypeEnum;
  viewMode?: ViewModeEnum;
  dependency?: DependencyEnum;
  isRequired?: boolean;
  sortOrder?: number;
  description?: string;
  values?: UpdateCustomOptionValuesInput[];
}

export interface MutationUpdateCustomOptionArgs {
  optionId: ApiCustomOption['id'];
  customOption: UpdateOptionInput;
}

export interface MutationUpdateCustomOptionOutput {
  updateCustomOption: {
    status: string;
    code: string;
    message?: string;
  };
}

/**
 * mutation removeCustomOption
 */
export interface MutationRemoveCustomOptionArgs {
  optionId: ApiCustomOption['id'];
}

export interface MutationRemoveCustomOptionOutput {
  removeCustomOption: {
    status: string;
    code: string;
    message?: string;
  };
}

/**
 * mutation updateProductQuantity.
 */
export interface MutationUpdateProductQuantityArgs {
  stockId: ProductInventory['id'];
  qty: ProductInventory['qty'];
}

export interface MutationUpdateProductQuantityOutput {
  updateInventoryItem: {
    status: string;
    code: string;
    message?: string;
  };
}

/**
 * mutation login
 */
export interface MutationLoginArgs {
  username: string;
  password: string;
}

export interface MutationLoginOutput {
  login?: {
    firstname: string;
    lastname: string;
    username: string;
    email: string;
  };
}
