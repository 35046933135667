import type { ReactNode } from 'react';
import { useEffect, useMemo, useState } from 'react';
import SearchFiltersContext from './context';
import { FilterType } from './types';
import type { FilterInterface } from './types';
import { dateObjectToIso, daysAway, daysEnd } from '@odo/utils/date';
import { applyFilterDefault } from './helpers';
import { AttributeCode } from '@odo/types/api';
import { dismiss, loading } from '@odo/utils/toast';
import { useAttributeOptions } from '@odo/hooks/attributes';

export const initialFilters: FilterInterface[] = [
  {
    label: 'Brand',
    key: 'brand',
    exact: false,
    active: false,
    type: FilterType.text,
  },
  {
    label: 'Active From Date',
    key: 'activeFromDate',
    exact: false,
    active: false,
    step: 1,
    type: FilterType.date,
    value: dateObjectToIso(daysAway(1), true),
    defaultValue: () => dateObjectToIso(daysAway(1), true),
  },
  {
    label: 'Cost',
    key: 'cost',
    active: false,
    type: FilterType.range,
  },
  {
    label: 'ID',
    key: 'id',
    exact: true,
    active: false,
    type: FilterType.text,
  },
  {
    label: 'Name',
    key: 'name',
    exact: false,
    active: false,
    type: FilterType.text,
  },
  {
    label: 'Active To Date',
    key: 'activeToDate',
    exact: false,
    active: false,
    step: 0,
    type: FilterType.date,
    value: dateObjectToIso(daysEnd(1), true),
    defaultValue: () => dateObjectToIso(daysEnd(1), true),
  },
  {
    label: 'Price',
    key: 'price',
    active: false,
    type: FilterType.range,
  },
  {
    label: 'Status',
    key: 'status',
    active: false,
    value: true,
    defaultValue: true,
    type: FilterType.boolean,
  },
  {
    label: 'SKU',
    key: 'sku',
    exact: false,
    active: false,
    type: FilterType.text,
  },
  {
    label: 'Account Manager',
    key: 'buyer',
    exact: true,
    active: false,
    type: FilterType.select,
    options: [],
  },
  {
    label: 'Daily Shops',
    key: 'categories',
    exact: false,
    active: false,
    type: FilterType.search,
  },
  {
    label: 'Product Listing',
    key: 'listingPages',
    exact: false,
    active: false,
    type: FilterType.multiSelect,
    options: [],
  },
];

const FILTER_TOAST_ID = 'prepping-filters';

const SearchFiltersProvider = ({ children }: { children: ReactNode }) => {
  const [isReady, setIsReady] = useState(false);
  const [isLoadingDeals, setIsLoadingDeals] = useState(false);
  const [filters, setFilters] = useState<FilterInterface[]>(
    initialFilters.map(filter => ({
      ...filter,
      value: applyFilterDefault(filter),
    }))
  );

  const buyers = useAttributeOptions(AttributeCode.buyer);

  const listingPages = useAttributeOptions(AttributeCode.listingPages);

  /**
   * Setting buyers attributes.
   */
  useEffect(() => {
    if (isReady || buyers.length === 0) return;

    setFilters(filters => [
      ...filters.map(filter => {
        if (filter.key === 'buyer') {
          filter.options = buyers
            .filter(buyer => !!buyer.key && buyer.key !== 'NONE')
            .sort((a, b) => a.key.localeCompare(b.key))
            // search uses the IDs instead of the enums like create/update
            .map(buyer => ({ ...buyer, value: buyer.originalData.value }));
        }
        if (filter.key === 'listingPages') {
          let defaultValue: string | undefined;
          filter.options = listingPages.map(page => {
            if (page.originalData.key === 'DAILY_DEALS') {
              defaultValue = page.originalData.value;
            }
            return {
              ...page,
              value: page.originalData.value,
              key: page.label,
            };
          });
          if (defaultValue) {
            filter.defaultValue = defaultValue;
          }
        }
        return filter;
      }),
    ]);

    setIsReady(true);
  }, [isReady, buyers, listingPages]);

  /**
   * Loading status indicator.
   */
  useEffect(() => {
    if (!isReady) {
      loading('Preparing filters. Please wait a moment...', {
        id: FILTER_TOAST_ID,
        position: 'top-center',
      });
    } else {
      dismiss(FILTER_TOAST_ID);
    }
  }, [isReady]);

  const value = useMemo(
    () => ({ isReady, filters, setFilters, isLoadingDeals, setIsLoadingDeals }),
    [isReady, filters, isLoadingDeals]
  );

  return (
    <SearchFiltersContext.Provider value={value}>
      {children}
    </SearchFiltersContext.Provider>
  );
};

export default SearchFiltersProvider;
