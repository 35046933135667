import { cssColor } from '@odo/utils/css-color';
import { Flex } from '@odo/components/elements/layout/flex';
import Button from '@odo/components/elements/button';
import { NavItem } from '@odo/components/layouts/app-container';
import {
  FaCalculator as IconCalculator,
  FaPen as IconEdit,
} from 'react-icons/fa';
import { useCurrentProduct } from '@odo/contexts/product-editor';
import { getAdminUrl } from '@odo/screens/deal/editor/helpers';
import { Status } from '@odo/screens/deal/editor/types';
import ResetButton from './reset-button';
import StatusButton from './status-button';
import SaveButton from './save-button';
import DuplicateButton from './duplicate-button';
import { useDealId, useValidity } from '@odo/screens/deal/editor/hooks';
import Tooltip from '@odo/components/widgets/tooltip';
import { generatePath } from 'react-router-dom';

// TODO: remove when we remove the RP deal editor
const OldDealEditorButton = () => {
  const { id } = useDealId();

  return (
    <Tooltip
      showDelay={250}
      content={() => 'Edit in old deal editor'}
      color={cssColor('palette-yellow')}
    >
      <NavItem justifyContent="center" height="42px">
        <a
          href={generatePath('/old/deals/editor/:dealId?/buyer-and-supplier', {
            dealId: id?.toString() || null,
          })}
        >
          {/* mobile */}
          <Button
            hue="yellow"
            variant="flat"
            display={['flex', 'none']}
            circular
            px={2}
            py={2}
          >
            <IconEdit />
          </Button>

          {/* tablet up */}
          <Button hue="yellow" variant="flat" display={['none', 'flex']} px={2}>
            <IconEdit />
            OLD EDITOR
          </Button>
        </a>
      </NavItem>
    </Tooltip>
  );
};

const Toolbar = () => {
  const currentProduct = useCurrentProduct();
  const validity = useValidity();

  return (
    <Flex
      flexDirection="row"
      gap={[2, 3]}
      justifyContent={['space-between', 'flex-end']}
    >
      {!!currentProduct?.id && (
        <>
          <OldDealEditorButton />

          <NavItem justifyContent="center" height="42px" width="42px">
            <DuplicateButton />
          </NavItem>

          <a
            href={getAdminUrl({
              dest: 'priceCalculator',
              productId: currentProduct.id,
            })}
            target="_blank"
            rel="noreferrer"
          >
            <NavItem justifyContent="center" height="42px" width="42px">
              <Button hue="grey" variant="flat" circular px={1} py={1}>
                <IconCalculator size={16} color={cssColor('grey')} />
              </Button>
            </NavItem>
          </a>
        </>
      )}

      <NavItem justifyContent="center" height="42px">
        {!!currentProduct?.id && <ResetButton />}
        <SaveButton status={validity?.status} />
      </NavItem>

      <NavItem
        px={0}
        py={0}
        height="42px"
        width={validity?.status === Status.valid ? '42px' : undefined}
        justifyContent={
          validity?.status === Status.valid ? 'center' : 'space-between'
        }
      >
        <StatusButton validity={validity} />
      </NavItem>
    </Flex>
  );
};

export default Toolbar;
