import type { EditorProductInterface } from '@odo/types/portal';
import { SizeInfoImageKey } from '@odo/types/portal';

/**
 * NOTE: we search for the SKU fieldId specifically when saving the deal.
 *
 * If we accidentally change the fieldId in one location, but not another,
 * we could end up with unexpected behaviour during save.
 *
 * So for this specific field at least we're gonna use a constant.
 *
 * If we find ourselves with more scenarios where we need to reference the same fieldId,
 * we might want to implement a more robust solution. But this is fine for now.
 */
export const SKU_FIELD_ID = 'sku';

/**
 * I'm still a bit too lazy & wary to implement a full solution for these fieldIds.
 * Let's keep an eye on them and see if it starts getting too much.
 */
export const CUSTOM_OPTIONS_QTY_FIELD_ID = 'customOptionsCumulativeQuantity';
export const SYNC_QTY_WITH_CUSTOM_OPTIONS_FIELD_ID =
  'syncQtyWithCumulativeQuantity';

/**
 * NOTE: I don't like having required fields on what's meant to be an "empty" object.
 * But I also didn't like having the UI needing to know the keys on this object.
 * For now this is the solution I've come up with.
 * It forces us to use this object in a few places, but it's not an extensive list.
 */
export const EMPTY_PRODUCT: EditorProductInterface = {
  sizeInfo: {
    mobile: {
      id: SizeInfoImageKey.mobile,
      label: 'Mobile',
    },
    tablet: {
      id: SizeInfoImageKey.tablet,
      label: 'Tablet',
    },
    desktop: {
      id: SizeInfoImageKey.desktop,
      label: 'Desktop',
    },
  },
};
