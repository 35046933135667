import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const NewEditorRedirect = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(location.pathname.replace('/new', ''));
  }, [location, navigate]);

  return 'Redirecting to new deal editor...';
};

export default NewEditorRedirect;
