import {
  Fieldset,
  InputNumeric,
  Switch,
} from '@odo/components/elements/form-fields';
import { Flex, Grid } from '@odo/components/elements/layout';
import {
  useChangeProduct,
  useCurrentProduct,
} from '@odo/contexts/product-editor';
import { cssColor } from '@odo/utils/css-color';
import Tooltip from '@odo/components/widgets/tooltip';
import { FiAlertCircle as IconAlert } from 'react-icons/fi';

const ActiveDateTime = () => {
  const currentProduct = useCurrentProduct();
  const change = useChangeProduct();

  return (
    <Fieldset>
      <legend>Active Date/Time</legend>

      <Grid gap={[2, 3]}>
        <Flex gap="12px" alignItems="center">
          <Switch
            checked={!!currentProduct.isAdvancedActiveDateTime}
            label="Advanced Options"
            width="3em"
            onChange={e => {
              const checked = e.target.checked;
              change({
                fieldId: 'isOneDayOnly',
                label: 'One Day Only',
                screen: 'product',
                apply: to => (to.isAdvancedActiveDateTime = checked),
              });

              if (
                !checked &&
                currentProduct.activeFromDate !== currentProduct.activeToDate
              ) {
                change({
                  fieldId: 'activeToDate',
                  label: 'Active To Date',
                  screen: 'product',
                  apply: to =>
                    (to.activeToDate = currentProduct.activeFromDate),
                });
              }
            }}
          />

          <Tooltip
            showDelay={250}
            placement="right"
            content={() =>
              'When enabled you can run a deal for multiple days or for a specific time.'
            }
          >
            <IconAlert color={cssColor('palette-blue')} size={16} />
          </Tooltip>
        </Flex>

        <Grid
          gridTemplateColumns={
            !currentProduct.isAdvancedActiveDateTime ? 'auto' : 'auto auto'
          }
          justifyContent="flex-start"
          alignItems="flex-end"
          gap={[2, 3]}
        >
          <InputNumeric
            required
            type="date"
            max="9999-12-31"
            label={
              !!currentProduct.isAdvancedActiveDateTime
                ? 'Active From'
                : 'Active Date'
            }
            value={currentProduct.activeFromDate || ''}
            onChange={e => {
              const value = e.target.value;
              change({
                fieldId: 'activeFromDate',
                label: 'Active From Date',
                screen: 'product',
                apply: to => (to.activeFromDate = value),
              });

              if (!currentProduct.isAdvancedActiveDateTime) {
                change({
                  fieldId: 'activeToDate',
                  label: 'Active To Date',
                  screen: 'product',
                  apply: to => (to.activeToDate = value),
                });
              }
            }}
            style={{ padding: '7px 5px', textIndent: '2px' }}
          />

          {!!currentProduct.isAdvancedActiveDateTime && (
            <>
              <InputNumeric
                type="time"
                min="00:00"
                max="23:59"
                value={currentProduct.activeFromTime || ''}
                onChange={e => {
                  const value = e.target.value;
                  change({
                    fieldId: 'activeFromTime',
                    label: 'Active From Time',
                    screen: 'product',
                    apply: to => (to.activeFromTime = value),
                  });
                }}
                style={{ padding: '6px 8px', textIndent: 'unset' }}
              />

              <InputNumeric
                required
                type="date"
                max="9999-12-31"
                label="Active To"
                value={currentProduct.activeToDate || ''}
                onChange={e => {
                  const value = e.target.value;
                  change({
                    fieldId: 'activeToDate',
                    label: 'Active To Date',
                    screen: 'product',
                    apply: to => (to.activeToDate = value),
                  });
                }}
                style={{ padding: '7px 5px', textIndent: '2px' }}
              />

              <InputNumeric
                type="time"
                min="00:00"
                max="23:59"
                value={currentProduct.activeToTime || ''}
                onChange={e => {
                  const value = e.target.value;
                  change({
                    fieldId: 'activeToTime',
                    label: 'Active To Time',
                    screen: 'product',
                    apply: to => (to.activeToTime = value),
                  });
                }}
                style={{ padding: '6px 8px', textIndent: 'unset' }}
              />
            </>
          )}
        </Grid>
      </Grid>
    </Fieldset>
  );
};

export default ActiveDateTime;
