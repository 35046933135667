import { useCurrentProduct } from '@odo/contexts/product-editor';
import { updateDraft } from '@odo/data/product/draft/cache';
import { useDealId } from '@odo/screens/deal/editor/hooks';
import { dateString } from '@odo/utils/date';
import { debounce } from '@odo/utils/debounce';
import { dismiss, notification } from '@odo/utils/toast';
import { produce } from 'immer';
import { useDeferredValue, useEffect } from 'react';

const PERSIST_DRAFT_ERROR_TOAST_ID = 'persist-draft-error';

const PersistDraftChanges = ({ id }: { id: number }) => {
  const currentProduct = useCurrentProduct();
  const deferredProduct = useDeferredValue(currentProduct);

  useEffect(() => {
    const syncDraft = async () => {
      try {
        // quickly set the updatedAt field in the draft metadata before persisting
        const syncProduct = produce(deferredProduct, draft => {
          if (draft.draft) {
            draft.draft.updatedAt = dateString(new Date(), { withTime: true });
          }
        });
        await updateDraft(id, syncProduct);
        dismiss(PERSIST_DRAFT_ERROR_TOAST_ID);
      } catch (e) {
        console.error(e);
        notification('Failed to cache draft changes.', {
          id: PERSIST_DRAFT_ERROR_TOAST_ID,
        });
      }
    };

    const controller = new AbortController();
    debounce(syncDraft, 500, controller.signal)();

    return () => controller.abort();
  }, [id, deferredProduct]);

  return null;
};

/**
 * Don't render the persist component when unnecessary to avoid running the effect constantly.
 */
const Wrapper = () => {
  const { id, isDraft } = useDealId();
  if (!id || !isDraft) return null;
  return <PersistDraftChanges id={id} />;
};

export default Wrapper;
