import { gql } from '@apollo/client';

export const UPLOAD_DEAL = gql`
  mutation UploadDeal($deal: String!) {
    uploadDeal(deal: $deal)
  }
`;

export const CREATE_ODO_PRODUCT = gql`
  mutation CreateODOProduct($input: CreateProduct!) {
    createProduct(input: $input) {
      ... on ResponseMessage {
        responseStatus: status
        responseCode: code
      }
      ... on Product {
        id
        buyer
        salesAssistant
        supplier
        isSupplierNew
        type
        brand
        sku
        url
        name
        shortName
        price
        cost
        rebateDiscount
        adminCost
        isDisplayRetail
        retail
        isSavingsInRands
        activeFromDate
        activeToDate
        area
        isAlcoholic
        isHygienic
        isParallelImport
        isReferable
        additionalInfo
        calloutText
        lockdownText
        condition
        isFragile
        isReturnableToSupplier
        warrantyPeriod
        warranty
        features
        videos
        leftAdditionalInfo
        moreDetails
        status
        taxClass
        pricing
        isLunchtimeProduct
        isInLunchtimeProductMailer
        isBestSeller
        platform
        isMainDeal
        campaign
        campaignMailer
        isBuyerSignedOff
        isSupplierSignedOff
        isFinalSignOff
        isSellout
        metaDescription
        metaTitle
        specialPrice
        visibility
        setNewFromDate
        setNewToDate
        countryManufactured
        applyMap
        manufacturerDisplayActualPrice
        manufacturerRetailPrice
        isGoogleCheckout
        metaKeywords
        isShippingApplied
        width
        length
        height
        weight
        isShippedIndividually
        pillOne
        pillTwo
        shippingCost
        isDeliveredBySupplier
        supplierRepacks
        originalStock
        surcharge
        surcharges {
          key
          value
        }
        discount
        priority
        isMembersOnly
        description
        shortDescription
        tabContentOne
        tabContentTwo
        customerDeliveryTime
        isNotStaffPurchase
        dealType
        isSampleReceived
        isPhotographedByStudio
      }
    }
  }
`;

export const UPDATE_ODO_PRODUCT_MANAGE_DEALS = gql`
  mutation UpdateODOProductForManageDeals(
    $productId: ID!
    $activeFromDate: String!
    $activeToDate: String!
    $priority: Int!
  ) {
    updateProduct(
      productId: $productId
      input: {
        activeFromDate: $activeFromDate
        activeToDate: $activeToDate
        priority: $priority
      }
    ) {
      ... on Product {
        id
      }
    }
  }
`;

export const UPDATE_ODO_PRODUCT = gql`
  mutation UpdateODOProduct($productId: ID!, $input: UpdateProduct!) {
    updateProduct(productId: $productId, input: $input) {
      ... on ResponseMessage {
        responseStatus: status
        responseCode: code
      }
      ... on Product {
        id
        buyer
        salesAssistant
        supplier
        isSupplierNew
        type
        brand
        sku
        url
        name
        shortName
        price
        cost
        rebateDiscount
        adminCost
        isDisplayRetail
        retail
        isSavingsInRands
        activeFromDate
        activeToDate
        area
        isAlcoholic
        isHygienic
        isParallelImport
        isReferable
        additionalInfo
        calloutText
        lockdownText
        condition
        isFragile
        isReturnableToSupplier
        warrantyPeriod
        warranty
        features
        videos
        leftAdditionalInfo
        moreDetails
        status
        taxClass
        pricing
        isLunchtimeProduct
        isInLunchtimeProductMailer
        isBestSeller
        platform
        isMainDeal
        campaign
        campaignMailer
        isBuyerSignedOff
        isSupplierSignedOff
        isFinalSignOff
        isSellout
        metaDescription
        metaTitle
        specialPrice
        visibility
        setNewFromDate
        setNewToDate
        countryManufactured
        applyMap
        manufacturerDisplayActualPrice
        manufacturerRetailPrice
        isGoogleCheckout
        metaKeywords
        isShippingApplied
        width
        length
        height
        weight
        isShippedIndividually
        pillOne
        pillTwo
        shippingCost
        isDeliveredBySupplier
        supplierRepacks
        originalStock
        surcharge
        surcharges {
          key
          value
        }
        discount
        priority
        isMembersOnly
        description
        shortDescription
        tabContentOne
        tabContentTwo
        isNotStaffPurchase
        dealType
        buyInStockType
        customerDeliveryTime
        isSampleReceived
        isPhotographedByStudio
      }
    }
  }
`;
