/**
 * TODO: BP-664: this side-effect might not be necessary after we move custom options into the product context
 */
import { useCustomOptionsEditorContext } from '@odo/contexts/custom-options-editor';
import {
  CUSTOM_OPTIONS_QTY_FIELD_ID,
  SYNC_QTY_WITH_CUSTOM_OPTIONS_FIELD_ID,
  useChangeProduct,
  useCurrentProduct,
  useProductEditor,
} from '@odo/contexts/product-editor';
import { getCumulativeQty } from '@odo/data/custom-options/utils';
import { dismiss, notification } from '@odo/utils/toast';
import { useDeferredValue, useEffect, useRef } from 'react';

const SYNC_CUMULATIVE_QTY_TOAST_ID = 'sync-qty-with-cumulative-qty';

const SyncCustomOptionsQty = () => {
  const currentProduct = useCurrentProduct();
  const change = useChangeProduct();
  const { undoChange } = useProductEditor();

  const { editorCustomOptions, autoSumEnabled } =
    useCustomOptionsEditorContext();
  const deferredCustomOptions = useDeferredValue(editorCustomOptions);
  const prevCumulativeQty = useRef(
    currentProduct.customOptionsCumulativeQuantity
  );

  useEffect(() => {
    const qty = getCumulativeQty(deferredCustomOptions, autoSumEnabled);
    if (qty === prevCumulativeQty.current) return;
    prevCumulativeQty.current = qty;

    change({
      fieldId: CUSTOM_OPTIONS_QTY_FIELD_ID,
      label: 'Custom Options Quantity Total',
      screen: 'price-and-custom-options',
      apply: to => (to.customOptionsCumulativeQuantity = qty),
    });

    if (typeof qty === 'undefined') {
      undoChange(SYNC_QTY_WITH_CUSTOM_OPTIONS_FIELD_ID);
      dismiss(SYNC_CUMULATIVE_QTY_TOAST_ID);
      return;
    }

    // this doesn't need to be debounced because we show a persistent (dismissible) notification
    // instead of adding a new one on each change
    const { undo } = change({
      fieldId: SYNC_QTY_WITH_CUSTOM_OPTIONS_FIELD_ID,
      label: 'Sync Qty With Custom Options Quantity Total',
      screen: 'shipping-and-inventory',
      apply: to =>
        (to.inventory = to.inventory
          ? { ...to.inventory, qty: { string: qty.toString(), number: qty } }
          : undefined),
    });

    const toastId = notification(
      <span>
        Quantity Available automatically updated to{' '}
        <b style={{ fontFamily: 'monospace' }}>{qty}</b> to match Custom Options
        Quantity Total
      </span>,
      {
        id: SYNC_CUMULATIVE_QTY_TOAST_ID,
        duration: Infinity,
        messageOptions: {
          dismissible: true,
          action: {
            label: 'Undo',
            callback: () => {
              undo();
              dismiss(toastId);
            },
          },
        },
      }
    );
  }, [autoSumEnabled, deferredCustomOptions, change, undoChange]);

  return null;
};

export default SyncCustomOptionsQty;
