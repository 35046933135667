import { Grid } from '@odo/components/elements/layout';
import { Heading, Text } from '@odo/components/elements/typography';
import {
  useChangeProduct,
  useCurrentProduct,
} from '@odo/contexts/product-editor';
import { cssColor } from '@odo/utils/css-color';
import { useCallback, useMemo, useRef } from 'react';
import { FaRegImages as IconImages } from 'react-icons/fa';
import uuid from '@odo/utils/uuid';
import type { EditorProductImage } from '@odo/types/portal';
import FileDropzone from '@odo/components/widgets/file-dropzone';
import { ACCEPTED_IMAGE_TYPES } from '@odo/screens/deal/editor/constants';
import { UnstyledButton } from '@odo/components/elements/button';
import type { FileDropzoneRef } from '@odo/components/widgets/file-dropzone/file-dropzone';

const ImageUpload = () => {
  const currentProduct = useCurrentProduct();
  const change = useChangeProduct();

  const fileDropzoneRef = useRef<FileDropzoneRef | null>(null);

  const highestPosition = useMemo(() => {
    let highest = 0;
    (currentProduct.images || []).forEach(({ position }) => {
      if (position && position > highest) {
        highest = position;
      }
    });
    return highest;
  }, [currentProduct.images]);

  const onFileDrop = useCallback(
    (files: File[]) => {
      let nextPosition = highestPosition;
      for (const file of files) {
        const newId = uuid();
        const newImage: EditorProductImage = {
          id: newId,
          file,
          position: ++nextPosition,
          // NOTE: we could put the file name in here as the label, but I feel that'll be an inconvenience
          // label: file.name,
        };

        change({
          fieldId: `images.upload.${newId}`,
          label: `Image upload: "${file.name}"`,
          screen: 'images-and-videos',
          apply: to => {
            to.images = to.images ? [...to.images, newImage] : [newImage];
          },
        });
      }
    },
    [change, highestPosition]
  );

  return (
    <Grid gap={2}>
      <FileDropzone
        ref={fileDropzoneRef}
        acceptedTypes={ACCEPTED_IMAGE_TYPES}
        onFileDrop={onFileDrop}
        multiple
      >
        <Grid
          gap={[2, 3]}
          justifyContent="center"
          justifyItems="center"
          px={3}
          py={4}
        >
          <IconImages size={60} />

          <Heading fontSize={2}>
            Drag images here or{' '}
            <UnstyledButton onClick={() => fileDropzoneRef.current?.click()}>
              click to upload.
            </UnstyledButton>
          </Heading>
        </Grid>
      </FileDropzone>

      <Text
        color={cssColor('text-muted')}
        textAlign={['left', 'right']}
        fontStyle="italic"
      >
        Accepted file types: {ACCEPTED_IMAGE_TYPES.join(', ')}
      </Text>
    </Grid>
  );
};

export default ImageUpload;
