import Button from '@odo/components/elements/button';
import { useChangeProduct } from '@odo/contexts/product-editor';
import {
  FaRegEye as IconEye,
  FaRegEyeSlash as IconEyeClosed,
} from 'react-icons/fa';
import {
  TbTrash as IconTrash,
  TbTrashOff as IconTrashOff,
} from 'react-icons/tb';
import type { EditorProductImage } from '@odo/types/portal';
import Tooltip from '@odo/components/widgets/tooltip';
import {
  ImageActionDownload,
  ImageActionOpenInNewTab,
  ImageActionZoom,
} from '@odo/screens/deal/editor/widgets/images/image-actions';

const ImageActionHide = ({ image }: { image: EditorProductImage }) => {
  const change = useChangeProduct();

  return (
    <Tooltip
      showDelay={1250}
      content={() => (image.isHidden ? 'Include Image' : 'Exclude Image')}
    >
      <Button
        hue="grey"
        variant="flat"
        px={0}
        py={0}
        onClick={() => {
          const shouldHide = !image.isHidden;
          change({
            fieldId: `images.${image.id}.isHidden`,
            label: shouldHide ? 'Exclude Image' : 'Include Image',
            screen: 'images-and-videos',
            apply: to => {
              to.images = to.images
                ? to.images.map(i =>
                    i.id === image.id ? { ...i, isHidden: shouldHide } : i
                  )
                : to.images;
            },
          });
        }}
      >
        {image.isHidden ? <IconEyeClosed size={16} /> : <IconEye size={16} />}
      </Button>
    </Tooltip>
  );
};

const ImageActionDelete = ({ image }: { image: EditorProductImage }) => {
  const change = useChangeProduct();

  return (
    <Tooltip
      showDelay={1250}
      content={() => (image.shouldDelete ? 'Cancel Delete' : 'Delete Image')}
    >
      <Button
        hue="grey"
        variant="flat"
        px={0}
        py={0}
        onClick={() => {
          const shouldDelete = !image.shouldDelete;
          change({
            fieldId: `images.${image.id}.shouldDelete`,
            label: shouldDelete ? 'Delete Image' : 'Cancel Delete',
            screen: 'images-and-videos',
            apply: to => {
              to.images = to.images
                ? to.images.map(i =>
                    i.id === image.id ? { ...i, shouldDelete } : i
                  )
                : to.images;
            },
          });
        }}
      >
        {image.shouldDelete ? (
          <IconTrashOff size={14} />
        ) : (
          <IconTrash size={14} />
        )}
      </Button>
    </Tooltip>
  );
};

const ImageActions = ({ image }: { image: EditorProductImage }) => (
  <>
    <ImageActionHide image={image} />
    <ImageActionDelete image={image} />
    <ImageActionDownload image={image} />
    <ImageActionOpenInNewTab image={image} />
    <ImageActionZoom image={image} />
  </>
);

export default ImageActions;
